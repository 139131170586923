import { render, staticRenderFns } from "./LatestReleaseList.vue?vue&type=template&id=3555e284&scoped=true&lang=pug&"
import script from "./LatestReleaseList.vue?vue&type=script&lang=js&"
export * from "./LatestReleaseList.vue?vue&type=script&lang=js&"
import style0 from "./LatestReleaseList.vue?vue&type=style&index=0&id=3555e284&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3555e284",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/apps/dj-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3555e284')) {
      api.createRecord('3555e284', component.options)
    } else {
      api.reload('3555e284', component.options)
    }
    module.hot.accept("./LatestReleaseList.vue?vue&type=template&id=3555e284&scoped=true&lang=pug&", function () {
      api.rerender('3555e284', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/src/dj/medium/latest-releases/components/LatestReleaseList.vue"
export default component.exports